<template>
  <div>
    <div class="container-fluid">
      <div class="row page-titles">
        <div class="col-md-5 align-self-center mt-2">
          <h4 class="text-themecolor">Dashboard</h4>
        </div>
        <div class="col-md-7 align-self-center text-right">
          <div class="d-flex justify-content-end align-items-center">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
              <li class="breadcrumb-item active">Dashboard</li>
            </ol>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-8">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title mb-0">Howdy Administrator!</h5>
              <p>Welcome to the <strong>Illuminate Asia</strong> Website Administrator Page <br>You are on the
              PROTECTED page of the <strong>Illuminate Asia</strong> Website Content Management system.</p>
              <p> This page is specifically intended ONLY for website administrators who will manage content.<br>
                Before making arrangements, there are few things to note:</p>
              <ol>
                <li>Save and secure your Username and Password to avoid things that are not desirable.</li>
                <li>Prepare the material or content that will be updated first.</li>
                <li>Also prepare photos, videos or other related material, to facilitate the updating process.</li>
                <li>If there is any questions about using features in this backoffice, you can ask the web developer contact below.</li>
                <li>Use the latest version of the browser to get the compatibility features in this backoffice.</li>
              </ol>
              <p>Web Developer Contact :<br> Email : hello@lingkar9.com<br> Phone : +62-21-744-6929 </p>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="card">

            <div class="card-header">
              <h5 class="card-title mb-0">Overview</h5>
            </div>
          </div>
          <div class="card">
            <router-link :to="{name:'Clients'}">
              <div class="card-body">
                <div class="d-flex flex-row">
                  <div class="round align-self-center round-warning"><i class="icon-people"></i></div>
                  <div class="m-l-10 align-self-center">
                    <h3 class="m-b-0">{{countClients}}</h3>
                    <h5 class="text-muted m-b-0 f_14">Active Clients Total </h5>
                  </div>
                </div>
              </div>
            </router-link>
          </div>

          <div class="card">
            <router-link :to="{name:'OurWork'}">
              <div class="card-body">
                <div class="d-flex flex-row">
                  <div class="round align-self-center round-success"><i class="ti-image"></i></div>
                  <div class="m-l-10 align-self-center">
                    <h3 class="m-b-0">{{countStudies}}</h3>
                    <h5 class="text-muted m-b-0 f_14">Active Case Studies Total </h5>
                  </div>
                </div>
              </div>
            </router-link>
          </div>

          <div class="card">
            <router-link :to="{name:'Blog'}">
              <div class="card-body">
                <div class="d-flex flex-row">
                  <div class="round align-self-center round-danger"><i class="ti-layout-list-thumb"></i></div>
                  <div class="m-l-10 align-self-center">
                    <h3 class="m-b-0">{{countArticles}}</h3>
                    <h5 class="text-muted m-b-0 f_14">Active Articles Total</h5>
                  </div>
                </div>
              </div>
            </router-link>
          </div>

          <div class="card">
            <router-link :to="{name:'Blog'}">
              <div class="card-body">
                <div class="d-flex flex-row">
                  <div class="round align-self-center round-info"><i class="ti-receipt"></i></div>
                  <div class="m-l-10 align-self-center">
                    <h3 class="m-b-0">{{countNews}}</h3>
                    <h5 class="text-muted m-b-0 f_14">Active News and Updates Total</h5>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center">
            <h5 class="card-title mb-0">Most Viewed Case Studies</h5>
            <router-link :to="{name: 'OurWork'}" class="btn btn-purple btn-rounded">
              See All Case Studies
              <i class="fa fa-arrow-circle-right ml-2"></i>
            </router-link>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>No.</th>
                <th>Title</th>
                <th width="120px">Views</th>
                <th>Category</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(v, k) in caseStudies" :key="k">
                <td>{{+k+1}}</td>
                <td>{{v.acs_title}}</td>
                <td>{{kFormatter(v.acs_viewer)}} views</td>
                <td>{{v.as_title}}</td>
              </tr>
              <tr v-if="(caseStudies||[]).length == 0">
                <td colspan="4"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <div class="d-flex align-items-center justify-content-between">
            <h5 class="card-title mb-0">Most Viewed Articles</h5>
            <router-link class="btn btn-purple btn-rounded" :to="{name: 'Article'}">
              See All Articles
              <i class="fa fa-arrow-circle-right ml-2"></i>
            </router-link>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>No.</th>
                <th>Title</th>
                <th width="120">Views</th>
                <th>Category</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(v,k) in articles" :key="k">
                <td>{{+k+1}}</td>
                <td>{{v.aa_title}}</td>
                <td>{{kFormatter(v.aa_viewer)}} views</td>
                <td>{{v.category}}</td>
              </tr>
              <tr v-if="(articles||[]).length == 0">
                <td colspan="4"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      
      <div class="card">
        <div class="card-body">
          <div class="d-flex align-items-center justify-content-between">
            <h5 class="card-title mb-0">Most Viewed News and Updates</h5>
            <router-link class="btn btn-purple btn-rounded" :to="{name: 'Article'}">
              See All News
              <i class="fa fa-arrow-circle-right ml-2"></i>
            </router-link>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>No.</th>
                <th>Title</th>
                <th width="120">Views</th>
                <th>Category</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(v,k) in news" :key="k">
                <td>{{+k+1}}</td>
                <td>{{v.aa_title}}</td>
                <td>{{kFormatter(v.aa_viewer)}} views</td>
                <td>{{v.category}}</td>
              </tr>
              <tr v-if="(news||[]).length == 0">
                <td colspan="4"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      
      <!-- <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center">
            <h5 class="card-title mb-0">Latest Unread Contact Messages</h5>
            <router-link class="btn btn-purple btn-rounded" :to="{name: 'ContactMessage'}">See All Messages<i class="fa fa-arrow-circle-right ml-2"></i></router-link>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>No.</th>
                <th>Name</th>
                <th>Email</th>
                <th>Subject</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(v,k) in messages" :key="k">
                <td>{{+k+1}}</td>
                <td>{{v.sc_name}}</td>
                <td>{{v.sc_email}}</td>
                <td>{{v.sc_subject}}</td>
                <td>
                  <a @click.prevent="setModal(v)" href="javascript:;" class="icon_action finger" v-tooltip="'Show Details'"><i class="ti-eye"></i></a>
                </td>
              </tr>
              <VModalVue :open.sync="isModalOpen" :inlineBackdrop="false" :title="'Detail Contact Form'">
                <template v-slot:title>
                  <h4 class="m-0">Detail Contact Form</h4>
                </template>

                <template v-slot:body>
                  <div class="modal-body">
                    <div class="row">
                      <div class="col-lg-12 mt-2">
                        <div class="form-group">
                          <label class="control-label">Full Name</label>
                          <p>{{ dataModal.sc_name }}</p>
                        </div>
                      </div>
                      <div class="col-lg-12 mt-2">
                        <div class="form-group">
                          <label class="control-label">Email Address</label>
                          <p>{{ dataModal.sc_email }}</p>
                        </div>
                      </div>
                      <div class="col-lg-12 mt-2">
                        <div class="form-group">
                          <label class="control-label">Phone Number</label>
                          <p>{{ dataModal.sc_phone }}</p>
                        </div>
                      </div>
                      <div class="col-lg-12 mt-2">
                        <div class="form-group">
                          <label class="control-label">Subject</label>
                          <p>{{ dataModal.sc_subject }}</p>
                        </div>
                      </div>
                      <div class="col-lg-12 mt-2">
                        <div class="form-group">
                          <label class="control-label">Message</label>
                          <div v-html="dataModal.sc_message"></div>
                        </div>
                      </div>
                    </div>

                  </div>
                </template>
              </VModalVue>
            </tbody>
          </table>
        </div>
      </div> -->

      <!-- ============================================================== -->
      <!-- charts -->
      <!-- ============================================================== -->

      <!-- ============================================================== -->
      <!-- End PAge Content -->
      <!-- ============================================================== -->
      <!-- ============================================================== -->
      <!-- Right sidebar -->
      <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Container fluid  -->
    <!-- ============================================================== -->
  </div>
</template>
<script>
  import GlobalVue from '@libs/Global'
  import Gen from '@libs/Gen.js'

  // let $ = global.jQuery
  // let _ = global._

  export default {
    extends: GlobalVue,
    data() {
      return {
        countClients: 0,
        countStudies: 0,
        countArticles: 0,
        countNews : 0,
        caseStudies: [],
        articles: [],
        news: [],
        messages: [],
      }
    },
    methods: {
      getCountClients() {
        Gen.apiRest(
          "/get/" + "Dashboard/countClients"
        ).
        then(res => {
            this.loadingOverlay = false
            this.countClients = res.data.count_clients;
          })
          .catch(() => {
            this.loadingOverlay = false
          })
      },

      getCountStudies() {
        Gen.apiRest(
          "/get/" + "Dashboard/countCaseStudies"
        ).
        then(res => {
            this.loadingOverlay = false
            this.countStudies = res.data.count_studies;
          })
          .catch(() => {
            this.loadingOverlay = false
          })
      },

      getCountArticles() {
        Gen.apiRest(
          "/get/" + "Dashboard/countArticles"
        ).
        then(res => {
            this.loadingOverlay = false
            this.countArticles = res.data.count_articles;
          })
          .catch(() => {
            this.loadingOverlay = false
          })
      },

      getCountNews() {
        Gen.apiRest(
          "/get/" + "Dashboard/countNews"
        ).
        then(res => {
            this.loadingOverlay = false
            this.countNews = res.data.count_news;
          })
          .catch(() => {
            this.loadingOverlay = false
          })
      },

      kFormatter(num) {
        return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
      }
      // setModal(data) {
      //   this.dataModal = data
      //   Gen.apiRest(
      //     "/do/"+this.modulePage,
      //     {data: {
      //       type: "updateStatus",
      //       id: data.sc_id
      //     }},
      //     "POST"
      //   )
      //     .then(() => {
      //       this.apiGet()
      //     })
      //   this.isModalOpen = true
      // },
    },
    mounted() {
      this.apiGet();
      this.getCountClients();
      this.getCountStudies();
      this.getCountArticles();
      this.getCountNews();
    },
  }
</script>